import { forwardRef, useCallback, useRef, useState } from 'react'

import { useMergeRefs } from '@floating-ui/react'
import { debounce } from 'throttle-debounce'

import { setAlpha } from '~/utils/color'
import { px } from '~/utils/css-unit'
import { useShouldActionActivatedInStory } from '~/utils/use-should-action-activated-in-story'
import { useTheme } from '~/utils/use-theme'

import type { CSSProp } from '../../types'
import type { Props as BoxProps } from '../box/box'
import { Box } from '../box/box'

type Props = BoxProps & {
    scrollableContent?: boolean
}
export const DialogBody = forwardRef<HTMLElement, Props>(
    ({ children, css, scrollableContent = false, padding = 'medium', ...props }, propsRef) => {
        const theme = useTheme()
        const isContentScrolledInStory = useShouldActionActivatedInStory()
        const [offsetScroll, setOffsetScroll] = useState(0)
        const [scrollSize, setScrollSize] = useState(0)

        const scrollableContentStyles: CSSProp = {
            overflowX: scrollableContent ? 'hidden' : undefined,
            overflowY: scrollableContent ? 'auto' : undefined,
        }
        const gradientStyles = (direction: 'top' | 'bottom'): CSSProp => {
            const gradientSize = 40
            const gradientAngleMap: Record<string, string> = {
                top: '0',
                bottom: '-180',
            }
            const offsetX = 0

            return {
                position: 'sticky',
                [direction]: '-1px',
                pointerEvents: 'none',
                zIndex: 7,
                transition: 'opacity 0.195s ease-in',
                mixBlendMode: 'multiply',

                '&::before': {
                    content: '""',
                    display: 'block',
                    height: px(gradientSize),
                    background: `linear-gradient(${gradientAngleMap[direction]}deg, ${setAlpha(
                        theme.colors.backgroundEmphasis,
                        0,
                    ).toHex()} 0%, ${theme.colors.backgroundEmphasis} 100%)`,
                    position: 'absolute',
                    [direction]: 0,
                    left: offsetX,
                    right: offsetX,
                },
            }
        }
        const scrollOffsetUpdate = (element: Element | null, updateTopScroll?: boolean) => {
            if (element) {
                const scrollSizeFromNode = element.scrollHeight - element.clientHeight

                if (updateTopScroll) {
                    // eslint-disable-next-line functional/immutable-data
                    element.scrollTop = scrollSizeFromNode
                }

                setScrollSize(scrollSizeFromNode)
            }
        }

        // eslint-disable-next-line react/hook-use-state, @eslint-react/naming-convention/use-state
        const [contentWrapperObserver] = useState(
            () =>
                new ResizeObserver(
                    debounce<ResizeObserverCallback>(20, ([entry]) => {
                        if (entry?.target) {
                            scrollOffsetUpdate(entry.target)
                        }
                    }),
                ),
        )

        const containerRef = useRef<HTMLDivElement | null>(null)

        // eslint-disable-next-line react/hook-use-state, @eslint-react/naming-convention/use-state
        const [contentMutationObserver] = useState(
            () =>
                new MutationObserver(
                    debounce<MutationCallback>(20, (mutationsList) => {
                        mutationsList.forEach((mutation) => {
                            if (mutation.type === 'childList') {
                                scrollOffsetUpdate(containerRef.current)
                            }
                        })
                    }),
                ),
        )

        const handleContentScroll = (event: Event) => {
            if (event.target) {
                setOffsetScroll((event.target as Element).scrollTop)
            }
        }

        const callbackRef = useCallback(
            (node: Element | null) => {
                if (node !== null && scrollableContent) {
                    node.addEventListener('scroll', debounce(20, handleContentScroll))
                    contentWrapperObserver.observe(node)
                    contentMutationObserver.observe(node, { childList: true, subtree: true })

                    setTimeout(() => {
                        scrollOffsetUpdate(node)
                        setOffsetScroll(node.scrollTop)
                    }, 10)

                    if (isContentScrolledInStory) {
                        scrollOffsetUpdate(node, true)
                    }
                }

                if (node === null) {
                    contentWrapperObserver.disconnect()
                    contentMutationObserver.disconnect()
                }
            },
            [contentWrapperObserver, contentMutationObserver, scrollableContent, isContentScrolledInStory],
        )

        const refs = useMergeRefs([propsRef, callbackRef, containerRef])

        return (
            <Box position="relative" display="contents" overflow="visible">
                {scrollableContent && <Box css={gradientStyles('top')} opacity={offsetScroll > 0 ? 1 : 0} />}
                <Box
                    ref={refs}
                    padding={padding}
                    backgroundColor="backgroundContent"
                    css={[scrollableContentStyles, css]}
                    {...props}
                >
                    {children}
                </Box>
                {scrollableContent && (
                    <Box
                        css={gradientStyles('bottom')}
                        // the -2 correction in scrollSize is necessary for some displays
                        // probably related to the 90vh in modalbox size
                        opacity={scrollSize > 0 && offsetScroll < scrollSize - 2 ? 1 : 0}
                    />
                )}
            </Box>
        )
    },
)

DialogBody.displayName = 'DialogBody'
